import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {
  BILLING_PROVIDER_SETTINGS,
  BillingPeriod,
  BillingProductsFacadeService,
  ChargebeeSite,
  CheckoutFacade,
  CustomerSubscriptionsFacadeService,
  IBillingProductClient,
  ICustomerSubscription,
  JwtWrapperService,
  SubscriptionActivationFacade,
  SubscriptionActivationState,
  SubscriptionDowngradeError,
  SubscriptionEventNames,
} from '@ps/pricing-domain';
import {utilTrackById, utilTrackByIndex} from '@pui/cdk/track-by';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {SHARED_EVENT_BUS_SERVICE} from '@px/shared/event-bus';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  finalize,
  first,
  firstValueFrom,
  forkJoin,
  map,
  merge,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'px-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingComponent implements OnInit {
  private readonly COMMON_ACTION_LABEL = 'Buy now';

  private readonly billingProductsFacade = inject(BillingProductsFacadeService);
  private readonly jwtWrapperService = inject(JwtWrapperService);
  private readonly customerSubscriptionsFacadeService = inject(CustomerSubscriptionsFacadeService);
  private readonly checkoutFacade = inject(CheckoutFacade);
  private readonly translate = inject(TranslateService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly subscriptionActivationWaitingFacade = inject(SubscriptionActivationFacade);
  private readonly eventBus = inject(SHARED_EVENT_BUS_SERVICE);
  private readonly productFamilyProvider = inject(PRODUCT_FAMILY, {optional: true});
  private readonly billingProviderSettings = inject(BILLING_PROVIDER_SETTINGS, {optional: true});

  private productFamilyInternal: ProductFamily | undefined = undefined;
  private bundleIncludedInternal = true;
  private siteInternal?: ChargebeeSite;
  private isLoadingProductsInternal = true;
  private isLoadingSubscriptionsInternal = true;

  protected readonly utilTrackByIndex = utilTrackByIndex;

  showPeriodSelector = true;
  buyingProduct: IBillingProductClient | null = null;
  trackBy = utilTrackById;

  period$ = new BehaviorSubject<BillingPeriod>(BillingPeriod.YEARLY);
  billingProducts$ = new BehaviorSubject<IBillingProductClient[]>([]);
  activeCustomerSubscriptions$ = new BehaviorSubject<ICustomerSubscription[]>([]);
  billingProductsByPeriod$: Observable<IBillingProductClient[]> = combineLatest([
    this.billingProducts$,
    this.period$,
  ]).pipe(
    map(([products, period]) => {
      return products
        .filter(p => p.period === period)
        .sort((pA, pB) => {
          if (pA.productFamily === pB.productFamily) {
            return pA.priceMonthly - pB.priceMonthly;
          }
          if (pA.productFamily === ProductFamily.BUNDLE) {
            return 1;
          }
          if (pB.productFamily === ProductFamily.BUNDLE) {
            return -1;
          }
          return 0;
        });
    })
  );

  @Input() sale10 = false; // TODO: Only for 10 year sale, then remove
  @Input() billingProviderSdkUrl?: string;
  @Input() billingProviderApiKey?: string;
  @Input() language?: string;
  @Input() successRedirectUrl = '/';
  @Input() cancelRedirectUrl = '/';
  @Input() highlightPlan: string | null = null;
  @Input() highlightPlanTitle: string | null = null;
  @Input() highlightPButtonText: string | null = null;
  @Input({transform: booleanAttribute}) disableUnavailablePlans = false;
  @Input({transform: booleanAttribute}) hideLogs = false;

  @Output('px-pricing-initialised') initialised$ = new EventEmitter<void>();
  @Output('px-pricing-invalid-jwt') invalidJwt$ = this.jwtWrapperService.invalidToken$;
  @Output('px-pricing-empty-jwt') emptyJwt$ = this.jwtWrapperService.emptyToken$;
  @Output('px-pricing-checkout-success') checkoutSuccess$ = new EventEmitter<IBillingProductClient>();
  @Output('px-pricing-subscription-activation-state') subscriptionActivationState$ =
    new EventEmitter<SubscriptionActivationState>();
  @Output('px-pricing-checkout-error') checkoutError$ = new EventEmitter<Error>();
  @Output('px-pricing-checkout-open') checkoutOpen$ = new EventEmitter<IBillingProductClient>();
  @Output('px-pricing-checkout-closed') checkoutClosed$ = new EventEmitter<void>();
  @Output('px-pricing-upgrade-success') upgradeSuccess$ = new EventEmitter<IBillingProductClient>();
  @Output('px-pricing-buy-now-click') buy$ = new EventEmitter<IBillingProductClient>();

  @Input() set productFamily(value: ProductFamily | string | undefined) {
    this.productFamilyInternal = this.productFamilyFromString(value);
  }

  get productFamily(): ProductFamily | undefined {
    return (
      (this.productFamilyInternal ||
        (this.productFamilyProvider && this.productFamilyFromString(this.productFamilyProvider))) ??
      undefined
    );
  }

  @Input() set site(value: ChargebeeSite | string | undefined) {
    if (!value) {
      return;
    }

    if (Object.values(ChargebeeSite).includes(value as unknown as ChargebeeSite)) {
      this.siteInternal = value as unknown as ChargebeeSite;
    } else if (value in ChargebeeSite) {
      this.siteInternal = ChargebeeSite[value as unknown as keyof typeof ChargebeeSite];
    } else {
      throw new Error(
        `not a chargebee site '${value}'. Please select value of ChargebeeSite enum or one of ${Object.values(
          ChargebeeSite
        )} strings`
      );
    }
  }

  get site(): ChargebeeSite | undefined {
    return this.siteInternal;
  }

  @Input() set jwtRequired(value: boolean | string) {
    this.jwtWrapperService.jwtRequired = coerceBooleanProperty(value);
  }

  @Input() set period(value: BillingPeriod) {
    this.period$.next(value);
  }

  get period(): BillingPeriod {
    return this.period$.value;
  }

  @Input() set bundleIncluded(value: string | boolean) {
    if (typeof value === 'string') {
      if (!['true', 'false'].includes(value)) {
        throw new Error('bundle included prop should be "true" or "false"');
      }
    }
    this.bundleIncludedInternal = typeof value === 'string' ? value === 'true' : value;
  }

  get bundleIncluded(): boolean {
    return this.bundleIncludedInternal;
  }

  get activeProductFamilyCustomerSubscriptions(): ICustomerSubscription[] {
    return this.activeCustomerSubscriptions$.value.filter(as =>
      as.package.products.every(p => p.price?.billingProductFamily.productFamily === this.productFamily)
    );
  }

  get activeBundleSubscription(): ICustomerSubscription | undefined {
    return this.activeCustomerSubscriptions$.value.find(as =>
      as.package.products.some(p => p.price?.billingProductFamily.productFamily === ProductFamily.BUNDLE)
    );
  }

  get hasActiveCard(): boolean {
    return this.activeCustomerSubscriptions$.value.some(as => !!as.customer.paymentSourceCards?.length);
  }

  get skeletons(): number[] {
    return this.productFamily === ProductFamily.SA ? [1, 2, 3] : [1, 2, 3, 4];
  }
  set isLoadingProducts(value: boolean) {
    this.isLoadingProductsInternal = value;
    this.cdr.detectChanges();
  }
  set isLoadingSubscriptions(value: boolean) {
    this.isLoadingSubscriptionsInternal = value;
    this.cdr.detectChanges();
  }
  get isLoading(): boolean {
    return this.isLoadingProductsInternal || this.isLoadingSubscriptionsInternal;
  }

  get showPricingYearly(): boolean {
    return this.period === BillingPeriod.YEARLY;
  }

  get currencyCode$(): Observable<string | null> {
    return this.billingProducts$.pipe(map(products => products[0]?.currencyCode ?? null));
  }

  private get availableProductFamilies(): string {
    return Object.keys(ProductFamily).join(', ');
  }

  private productFamilyFromString(str: ProductFamily | string | undefined): ProductFamily {
    if (!str || !(str in ProductFamily)) {
      throw new Error(`product family '${str}' doesn't exists. Please set as one of ${this.availableProductFamilies}`);
    }

    return ProductFamily[str as keyof typeof ProductFamily];
  }

  private tryToGetProducts(): void {
    if (!this.productFamily) {
      throw new Error(
        `Product family in pricing component is undefined. Please set a product family as one of ${this.availableProductFamilies}`
      );
    }

    this.isLoadingProducts = true;

    combineLatest([
      this.billingProductsFacade.getProducts(this.productFamily, this.highlightPlan, this.highlightPlanTitle),
      this.bundleIncludedInternal
        ? this.billingProductsFacade.getProducts(
            ProductFamily.BUNDLE,
            this.highlightPlan,
            this.highlightPlanTitle,
            this.productFamily
          )
        : of([]),
    ])
      .pipe(
        map(([products, bundle]) => [...products, ...bundle]),
        finalize(() => (this.isLoadingProducts = false)),
        untilDestroyed(this)
      )
      .subscribe(products => this.billingProducts$.next(products));
  }

  private getCustomerActiveSubscriptions(): void {
    this.isLoadingSubscriptions = true;

    this.customerSubscriptionsFacadeService
      .getCustomerActiveSubscriptions()
      .pipe(
        finalize(() => (this.isLoadingSubscriptions = false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: activeSubs => this.activeCustomerSubscriptions$.next(activeSubs),
        error: err => {
          if (this.hideLogs) {
            return;
          }

          console.error(err);
        },
      });
  }

  private buyBundleSubscription(): void {
    if (!this.buyingProduct) {
      return;
    }

    this.openCheckout()
      .pipe(
        first(),
        catchError(() => EMPTY),
        tap(billingProduct => this.subscriptionActivationWaitingFacade.startWaitingActivationOnlyFor(billingProduct)),
        switchMap(() => this.cancelRemainingSubscriptionsIfNeeded())
      )
      .subscribe(isCancelSuccess => {
        if (!isCancelSuccess) {
          console.error('Cancelled the subscription with an error');
          this.subscriptionActivationWaitingFacade.stopWaitingActivation();
        }
      });
  }

  private buySubscription(): void {
    if (!this.buyingProduct) {
      return;
    }

    this.openCheckout()
      .pipe(
        first(),
        catchError(() => EMPTY)
      )
      .subscribe(billingProduct => {
        this.subscriptionActivationWaitingFacade.startWaitingActivationOnlyFor(billingProduct, true);
      });
  }

  private updateSubscriptionWithinProductFamily(): void {
    if (!this.buyingProduct) {
      return;
    }

    const buyingProduct = this.buyingProduct;
    let activeSubscription: ICustomerSubscription | null = this.activeProductFamilyCustomerSubscriptions[0] ?? null;
    let observable$: Observable<unknown> = this.customerSubscriptionsFacadeService.updateCustomerActiveSubscription(
      activeSubscription.id,
      buyingProduct.id
    );

    if (!this.hasActiveCard) {
      observable$ = this.openCheckout();
      activeSubscription = null;
    }

    observable$
      .pipe(
        first(),
        catchError(() => EMPTY),
        tap(() => this.subscriptionActivationWaitingFacade.startWaitingActivationOnlyFor(buyingProduct, true)),
        switchMap(() => this.cancelRemainingProductFamilySubscriptionsIfNeeded(activeSubscription))
      )
      .subscribe(isCancelSuccess => {
        if (!isCancelSuccess) {
          console.error('Cancelled the subscription with an error');
          this.subscriptionActivationWaitingFacade.stopWaitingActivation();
        }

        this.upgradeSuccess$.emit(buyingProduct);
      });
  }

  private openCheckout(): Observable<IBillingProductClient> {
    if (!this.buyingProduct) {
      return EMPTY;
    }

    this.checkoutFacade.openCheckout({
      billingProduct: this.buyingProduct,
      redirectUrl: this.successRedirectUrl,
      cancelUrl: this.cancelRedirectUrl,
      couponCodes:
        this.period === 'Yearly' && this.isSale10(this.buyingProduct.productFamily) ? this.getSale10CouponCodes() : [],
    });

    return this.checkoutSuccess$;
  }

  private cancelRemainingProductFamilySubscriptionsIfNeeded(
    updatedSubscription: ICustomerSubscription | null = null
  ): Observable<boolean> {
    const remainingActiveSubscriptions = this.activeProductFamilyCustomerSubscriptions.filter(
      as => as.id !== updatedSubscription?.id
    );

    if (remainingActiveSubscriptions.length === 0) {
      return of(true);
    }

    return forkJoin(
      remainingActiveSubscriptions.map(as => this.customerSubscriptionsFacadeService.cancelCustomerSubscription(as.id))
    ).pipe(
      map(subs => subs.every(s => !!s)),
      catchError(e => {
        console.error(e);
        return of(false);
      })
    );
  }

  private cancelRemainingSubscriptionsIfNeeded(): Observable<boolean> {
    if (this.activeCustomerSubscriptions$.value.length === 0) {
      return of(true);
    }

    return forkJoin(
      this.activeCustomerSubscriptions$.value.map(as =>
        this.customerSubscriptionsFacadeService.cancelCustomerSubscription(as.id)
      )
    ).pipe(
      map(subs => subs.every(s => !!s)),
      catchError(e => {
        console.error(e);
        return of(false);
      })
    );
  }

  private subscribePeriodSelectorToggle(): void {
    this.billingProducts$.pipe(untilDestroyed(this)).subscribe(data => {
      const len = data.filter(item => item.period === BillingPeriod.MONTHLY);

      this.showPeriodSelector = !!len.length;
      this.cdr.markForCheck();
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.language) {
      await firstValueFrom(this.translate.use(this.language));
    }

    this.subscribePeriodSelectorToggle();
    this.tryToGetProducts();
    this.getCustomerActiveSubscriptions();

    const site = this.siteInternal || this.billingProviderSettings?.site;
    const billingProviderApiKey = this.billingProviderApiKey || this.billingProviderSettings?.apiKey;
    const billingProviderSdkUrl = this.billingProviderSdkUrl || this.billingProviderSettings?.sdkUrl;

    if (!site) {
      throw new Error('Site info for chargebee not provided.');
    }

    if (!billingProviderApiKey) {
      throw new Error('Api key for chargebee not provided.');
    }

    if (!billingProviderSdkUrl) {
      throw new Error('provide billing provider sdk url');
    }

    await this.checkoutFacade.initBillingProvider(site, billingProviderApiKey, billingProviderSdkUrl);

    this.checkoutFacade.checkoutSuccess$.pipe(untilDestroyed(this)).subscribe(this.checkoutSuccess$);
    this.checkoutFacade.checkoutError$.pipe(untilDestroyed(this)).subscribe(this.checkoutError$);
    this.checkoutFacade.checkoutClosed$.pipe(untilDestroyed(this)).subscribe(this.checkoutClosed$);
    this.checkoutFacade.checkoutOpen$.pipe(untilDestroyed(this)).subscribe(this.checkoutOpen$);

    this.subscriptionActivationWaitingFacade.activationState$
      .pipe(untilDestroyed(this))
      .subscribe(this.subscriptionActivationState$);

    this.subscriptionActivationWaitingFacade.activationState$
      .pipe(
        filter(state => !!(state.isActivating === false && state.activationResult?.isActivated)),
        untilDestroyed(this)
      )
      .subscribe(() => this.getCustomerActiveSubscriptions());

    this.checkoutSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(data => this.eventBus.emit({key: SubscriptionEventNames.CHECKOUT_SUCCESS, data}));

    this.checkoutError$
      .pipe(untilDestroyed(this))
      .subscribe(data => this.eventBus.emit({key: SubscriptionEventNames.CHECKOUT_ERROR, data}));

    this.checkoutClosed$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.eventBus.emit({key: SubscriptionEventNames.CHECKOUT_CLOSE}));
    this.checkoutOpen$
      .pipe(untilDestroyed(this))
      .subscribe(data => this.eventBus.emit({key: SubscriptionEventNames.CHECKOUT_OPEN, data}));

    this.upgradeSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(data => this.eventBus.emit({key: SubscriptionEventNames.UPGRADE_SUCCESS, data}));

    this.subscriptionActivationState$.pipe(untilDestroyed(this)).subscribe(data => {
      this.eventBus.emit({key: SubscriptionEventNames.SUBSCRIPTION_ACTIVATION_STATE, data});

      if (!data.isActivating && !!data.activationResult?.isActivated) {
        this.eventBus.emit({key: SubscriptionEventNames.SUBSCRIPTION_ACTIVATION_SUCCESS, data});
      }
    });

    merge(
      this.subscriptionActivationWaitingFacade.activationState$.pipe(filter(state => state.isActivating === false)),
      this.checkoutFacade.checkoutError$,
      this.checkoutFacade.checkoutClosed$
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.buyingProduct = null;
        this.cdr.detectChanges();
      });

    this.initialised$.emit();
  }

  @HostListener('px-pricing-set-jwt', ['$event.detail'])
  setJwt(token: string | null): void {
    //NOTE: when pass {detail: undefined} from dispatch event it becomes null
    this.jwtWrapperService.jwt = token ?? undefined;
  }

  buy(billingProduct: IBillingProductClient): void {
    if (this.buyingProduct) {
      return;
    }

    this.buy$.next(billingProduct);
    this.eventBus.emit({key: SubscriptionEventNames.BUY_NOW_CLICK, data: billingProduct});
    this.buyingProduct = billingProduct;

    if (this.activeBundleSubscription) {
      this.subscriptionActivationWaitingFacade.stopWaitingActivation();
      this.buyingProduct = null;
      this.checkoutError$.next(
        new SubscriptionDowngradeError(
          'It is forbidden to switch from a "bundle" subscription to a regular subscription'
        )
      );

      console.warn('It is forbidden to switch from a "bundle" subscription to a regular subscription');
    } else if (billingProduct.productFamily === ProductFamily.BUNDLE) {
      this.buyBundleSubscription();
    } else if (this.activeProductFamilyCustomerSubscriptions.length === 0) {
      this.buySubscription();
    } else {
      this.updateSubscriptionWithinProductFamily();
    }

    this.cdr.detectChanges();
  }

  isProductSubscribed(productId: string): boolean {
    return !!this.activeCustomerSubscriptions$.value.find(s => s.package.products.some(p => p.price?.id === productId));
  }

  getActionLabel(billingProductId: string): string {
    return billingProductId === this.highlightPlan && this.highlightPButtonText
      ? this.highlightPButtonText
      : this.COMMON_ACTION_LABEL;
  }

  isSale10(productFamily: string): boolean {
    return this.sale10 && productFamily === 'BUNDLE';
  }

  getSale10Price(price?: number): number {
    return Number(price) * 0.6;
  }

  getSale10CouponCodes(): string[] {
    return ['SA10AAB'];
  }
}
