import {InjectionToken} from '@angular/core';
import {SlideShowState} from '../../store/slideshow/slideshow.state';
import {Preferences} from '../../models/preferences.model';
import {IPSSTemplate} from '../../models/pss-template';
import {IPSSAudio} from '../../models/pss-audio.model';
import {TrackingProperties} from '@px/tracking/feature-tracking';

export const TRACKING_INFO_TOKEN = new InjectionToken<ITrackingInfo>('TrackingInfoToken');

export interface ITrackingInfo {
  slideshowInfo(slideshow: SlideShowState, preferences: Preferences): TrackingProperties;
  audioInfo(slideshow: SlideShowState, id: string): TrackingProperties;
  beatMatchingInfo(
    slideshow: SlideShowState,
    audio: IPSSAudio,
    noErrorPhotosCount: number,
    bmTemplate: IPSSTemplate
  ): TrackingProperties;
  audioAddingInfo(source: string, sort?: string): TrackingProperties;
  imageUploadInfo(
    slideshowUId: string,
    segmentId: string | number,
    imagesLength: number,
    isImagesDragged: boolean
  ): TrackingProperties;
  imageUploadingErrorInfo(
    slideshowUId: string,
    segmentId: string | number,
    failedImagesLength: number,
    errorType: string,
    fileType?: string
  ): TrackingProperties;
}
