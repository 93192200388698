import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {PaymentPopoverDomainModule} from '@ps/payment-popover-domain';
import {PricingDomainModule} from '@ps/pricing-domain';
import {MdsButtonComponent} from '@pui/components/button';
import {SkeletonModule} from '@pui/components/skeleton';
import {PuiTooltipDirective} from '@pui/components/tooltip';
import {PeriodSelectorComponent} from './components/period-selector/period-selector.component';
import {PlanCardComponent} from './components/plan-card/plan-card.component';
import {PlanFeatureComponent} from './components/plan-feature/plan-feature.component';
import {PricingComponent} from './components/pricing/pricing.component';
import {ToggleComponent} from './components/toggle/toggle.component';

@NgModule({
  imports: [
    CommonModule,
    PaymentPopoverDomainModule,
    PricingDomainModule,
    TranslateModule,
    OverlayModule,
    PuiTooltipDirective,
    MdsButtonComponent,
    SkeletonModule,
  ],
  declarations: [PricingComponent, ToggleComponent, PlanCardComponent, PlanFeatureComponent, PeriodSelectorComponent],
  exports: [PricingComponent],
})
export class PricingFeaturePricingCardsModule {}
