import {ChangeDetectorRef, Optional, Pipe, PipeTransform} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Languages, PlatformEnvironment} from '@px/shared/env';
import {AudioEnergyId} from '@px/audio-domain';

@UntilDestroy()
@Pipe({
  name: 'audioEnergy',
})
export class AudioEnergyPipe implements PipeTransform {
  private static readonly DEFAULT_KEY = 'Unknown';
  private static readonly LOW_KEY = 'Low';
  private static readonly LOW_MEDIUM_KEY = 'Low-Medium';
  private static readonly MEDIUM_KEY = 'Medium';
  private static readonly MEDIUM_HIGH_KEY = 'Medium-High';
  private static readonly HIGH_KEY = 'High';

  private input$ = new BehaviorSubject<number | null>(null);
  private value?: string;

  constructor(
    private readonly translate: TranslateService,
    private readonly platform: PlatformEnvironment,
    @Optional() private readonly cdr?: ChangeDetectorRef
  ) {
    this.input$
      .pipe(
        switchMap(value => this.getAsyncTranslate(value)),
        untilDestroyed(this)
      )
      .subscribe((translate: string) => {
        this.value = translate;
        this.cdr?.markForCheck();
      });
  }

  private getTranslate(
    method: 'async' | 'sync',
    value: AudioEnergyId | null,
    lang?: Languages
  ): Observable<string> | string {
    let fn: (key: string) => Observable<string> | string;

    if (lang) {
      if (method === 'async') {
        fn = (key: string): Observable<string> => this.translate.getTranslation(lang).pipe(map(values => values[key]));
      } else {
        fn = (key: string): string => this.translate.translations[lang][key]();
      }
    } else {
      if (method === 'async') {
        fn = this.translate.get.bind(this.translate);
      } else {
        fn = this.translate.instant.bind(this.translate);
      }
    }

    switch (value) {
      case AudioEnergyId.LOW:
        return fn(AudioEnergyPipe.LOW_KEY);

      case AudioEnergyId.LOW_MEDIUM:
        return fn(AudioEnergyPipe.LOW_MEDIUM_KEY);

      case AudioEnergyId.MEDIUM:
        return fn(AudioEnergyPipe.MEDIUM_KEY);

      case AudioEnergyId.MEDIUM_HIGH:
        return fn(AudioEnergyPipe.MEDIUM_HIGH_KEY);

      case AudioEnergyId.HIGH:
        return fn(AudioEnergyPipe.HIGH_KEY);

      default:
        return fn(AudioEnergyPipe.DEFAULT_KEY);
    }
  }

  private getSyncTranslate(value: AudioEnergyId | null, lang?: Languages): string {
    return this.getTranslate('sync', value, lang) as string;
  }

  private getAsyncTranslate(value: AudioEnergyId | null, lang?: Languages): Observable<string> {
    return this.getTranslate('async', value, lang) as Observable<string>;
  }

  getBaseTranslation(value: AudioEnergyId | null): string {
    return this.getSyncTranslate(value, this.platform.BASE_LANG);
  }

  transform(input: string | number | null): string {
    const inputParsed = input !== null ? (Number(input) as AudioEnergyId) : null;

    const {value} = this.input$;

    if (!value || value !== input) {
      this.input$.next(inputParsed);
    }

    return this.value ?? this.getSyncTranslate(inputParsed);
  }
}
