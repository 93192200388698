import {InjectionToken, Optional, Provider, SkipSelf} from '@angular/core';
import {aaIcons, caIcons, pxIcons} from './icon-presets';

export const MdsIconsToken = new InjectionToken<Record<string, string>[]>('Mds Icons Token');

export function mdsProvideIcons(icons: Record<string, string>): Provider[] {
  return [
    {
      provide: MdsIconsToken,
      useFactory: (parentIcons?: Record<string, string>[]): Record<string, string> => {
        return {
          ...parentIcons?.reduce((acc, icons) => ({...acc, ...icons}), {}),
          ...icons,
        };
      },
      deps: [[MdsIconsToken, new Optional(), new SkipSelf()]],
      multi: true,
    },
  ];
}

export const iconsProvider = mdsProvideIcons({...pxIcons, ...caIcons, ...aaIcons});
