import {inject, Injectable} from '@angular/core';
import {asyncScheduler, BehaviorSubject, filter, map, Observable, tap, throwError} from 'rxjs';
import {LocalStorageService} from '@px/shared-data-access-local-storage';
import {PhotographerAuthDataService} from '../infrastructrue/services/photographer-auth-data.service';

import {
  IJwtPayload,
  ISession,
  ISessionProviderService,
  ISessionState,
  Session,
  SessionProviderService,
  SessionSource,
} from '@px/shared/session-provider';
import {PHOTOGRAPHER_BACKEND_CLIENT_ID} from '../infrastructrue/tokens';
import jwtDecode from 'jwt-decode';
import {instanceToPlain, plainToInstance} from 'class-transformer';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {PlatformEnvironment} from '@px/shared/env';
import {WINDOW_TOKEN} from '@px/cdk/window';

// use it only if you are using ULF (common way) for auth otherwise use SessionProviderFacade
@Injectable()
export class PhotographerAuthFacade extends SessionProviderService implements ISessionProviderService {
  private readonly photographerAuthService = inject(PhotographerAuthDataService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly clientId = inject(PHOTOGRAPHER_BACKEND_CLIENT_ID);
  private readonly platformEnvironment = inject(PlatformEnvironment);
  private readonly window = inject(WINDOW_TOKEN);

  private readonly sessionIsNotStoredOrWrongFormat = 'Session is not stored or wrong format';
  private readonly refreshIdIsNotPresented = 'Refresh Id is not presented.';
  private readonly AUTH_STORAGE_ITEM_KEY = 'AUTH_PH_TOKEN';
  private readonly sessionStateInternal$ = new BehaviorSubject<ISessionState | null>(this.getInitialSessionState());

  private sessionSourceInternal: SessionSource | null = SessionSource.LOCAL_STORAGE;

  session$ = this.sessionStateInternal$.pipe(
    filter(Boolean),
    map(
      data =>
        instanceToPlain(data.session) as {
          token: string;
          refreshId: string;
          expiration: number;
        }
    )
  );

  get session(): ISession | null {
    return this.getSessionFromLocalstorage();
  }

  get sessionSource(): SessionSource | null {
    return this.sessionSourceInternal;
  }

  private removeSession(): void {
    this.localStorageService.removeItem(this.AUTH_STORAGE_ITEM_KEY);
    this.sessionStateInternal$.next(null);
    this.sessionSourceInternal = null;
  }

  private getInitialSessionState(): ISessionState | null {
    const session = this.getSessionFromLocalstorage();
    return (
      session && {
        session,
        sessionSource: SessionSource.LOCAL_STORAGE,
      }
    );
  }

  private getSessionFromLocalstorage(): ISession | null {
    const sessionRaw = this.localStorageService.getItem(this.AUTH_STORAGE_ITEM_KEY);

    try {
      if (sessionRaw) {
        const object = JSON.parse(sessionRaw);
        return plainToInstance(Session, object);
      }

      return null;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  oAuth(code: string): Observable<ISession> {
    return this.photographerAuthService.oAuth({
      clientId: this.clientId,
      code: code,
    });
  }

  auth(username: string, password: string): Observable<ISession> {
    if (!username || !password) {
      return throwError(() => marker('Invalid username or password'));
    }

    return this.photographerAuthService.auth({clientId: this.clientId, username, password});
  }

  refresh(): Observable<void> {
    if (this.session) {
      if (this.session.refreshId) {
        return this.photographerAuthService
          .refresh({
            clientId: this.clientId,
            refreshId: this.session.refreshId,
          })
          .pipe(
            tap(session => this.updateSession(session, SessionSource.REFRESH)),
            map(() => undefined)
          );
      }

      return throwError(() => this.refreshIdIsNotPresented);
    }

    return throwError(() => this.sessionIsNotStoredOrWrongFormat);
  }

  logOut(): void {
    this.removeSession();
  }

  hasSession(): boolean {
    return !!this.session?.token;
  }

  getSessionToken(): string | undefined {
    return this.session?.token;
  }

  updateSession(session: ISession, sessionSource: SessionSource): void {
    try {
      this.localStorageService.setItem(this.AUTH_STORAGE_ITEM_KEY, JSON.stringify(instanceToPlain(session)));
      this.sessionStateInternal$.next({session, sessionSource});
      this.sessionSourceInternal = sessionSource;
    } catch (e) {
      console.warn(e);
    }
  }

  getRoles(): string[] {
    const jwt = this.getSessionToken();

    if (!jwt) {
      return [];
    }

    try {
      const decodedJWT: IJwtPayload = jwtDecode(jwt);
      return decodedJWT.roles;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  getEmail(): string {
    if (this.session?.token) {
      try {
        const jwtPayload: IJwtPayload = jwtDecode(this.session.token);
        return jwtPayload.email;
      } catch (e) {
        return '';
      }
    }

    return '';
  }

  getId(): string {
    if (this.session?.token) {
      try {
        const jwtPayload: IJwtPayload = jwtDecode(this.session.token);
        return jwtPayload.id;
      } catch (e) {
        return '';
      }
    }

    return '';
  }

  getAuthUrl(next?: string): string {
    const url = new URL(this.platformEnvironment.AUTH_API_URL + '/client');

    url.searchParams.set('state', next ? this.window.location.origin + (next || '') : this.window.location.href);
    url.searchParams.set('client_id', this.platformEnvironment.PX_OAUTH_CLIENT_ID);

    return url.toString();
  }

  getLogOutUrl(): string {
    const url = new URL(this.platformEnvironment.AUTH_API_URL + '/logout');
    url.searchParams.set('state', this.window.btoa(this.getAuthUrl()));
    return url.toString();
  }

  handleNoSession(next?: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const authUrl = this.getAuthUrl(next);
      this.logOut();

      asyncScheduler.schedule(() => {
        this.window.location.href = authUrl;
      });

      observer.next(false);
    });
  }

  redirectToLogOut(): void {
    const logOutUrl = this.getLogOutUrl();
    this.logOut();

    asyncScheduler.schedule(() => {
      this.window.location.href = logOutUrl;
    });
  }
}
