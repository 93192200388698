import {IApiResponse as ApiResponse, IAuthService, TransitionTypeId} from '@px/shared/api';
import {Observable} from 'rxjs';

export interface IPssAuthService extends IAuthService {
  saveLastTransition(data: ISaveLastTransitionPicInput): Observable<ApiResponse>;
}

export interface ISaveLastTransitionPicInput {
  transition_id: TransitionTypeId;
  transition_duration: number;
}

export interface ISaveLastTransitionGqlInput {
  lastChosenTransitionType: TransitionTypeId;
  lastChosenTransitionDuration: number;
}
