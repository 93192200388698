import {NgModule} from '@angular/core';
import {PssTrackingService} from './services/pss-tracking.service';
import {PssThemesTrackingService} from './services/pss-themes-tracking.service';
import {FeatureTrackingModule} from '@px/tracking/feature-tracking';

@NgModule({
  imports: [FeatureTrackingModule],
  providers: [PssTrackingService, PssThemesTrackingService],
})
export class FeatureTrackingSmartSlidesModule {}
