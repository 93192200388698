import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, booleanAttribute} from '@angular/core';

@Component({
  selector: 'mds-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
  @ViewChild('skeleton', {static: true}) private skeleton: ElementRef | undefined;

  @Input({transform: booleanAttribute}) isAnimated = false;

  get skeletonAnimationDistance(): string {
    if (this.skeleton) {
      const {width, height} = this.skeleton.nativeElement.getBoundingClientRect();
      return `${width + height}px`; // when the skeleton has skewX(-45deg), the distance equals to width + height
    }
    return '100%';
  }
}
