import {ChangeDetectionStrategy, Component, ElementRef, inject, Input} from '@angular/core';
import {MdsIconsToken} from '../icon.provider';
import {MdsIconName} from '../icon-name.type';

export type MdsIcon = MdsIconName;

@Component({
  selector: 'mds-icon',
  standalone: true,
  template: '',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsIconComponent {
  private readonly elementRef = inject(ElementRef);
  private readonly icons = inject(MdsIconsToken, {optional: true}) ?? [];

  @Input() set icon(icon: MdsIcon) {
    this.setIcon(icon);
  }

  private setIcon(icon: MdsIcon): void {
    const propertyIcon = toPropertyIcon(icon);

    for (const icons of [...this.icons].reverse()) {
      if (icons[propertyIcon]) {
        this.elementRef.nativeElement.innerHTML = icons[propertyIcon];
        return;
      }
    }

    console.warn(`MdsIcon ${icon} is not provided with mdsProvideIcons`);
  }
}

function toPropertyIcon(str: string): string {
  return str
    .replace(/([^a-zA-Z0-9])+(.)?/g, (_, __, c) => (c ? c.toUpperCase() : ''))
    .replace(/[^a-zA-Z\d]/g, '')
    .replace(/^([A-Z])/, m => m.toLowerCase());
}
