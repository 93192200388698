import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkeletonComponent} from './components/skeleton/skeleton.component';
import {SkeletonBoneComponent} from './components/skeleton-bone/skeleton-bone.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SkeletonComponent, SkeletonBoneComponent],
  exports: [SkeletonComponent, SkeletonBoneComponent],
})
export class SkeletonModule {}
