import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of, tap} from 'rxjs';
import {PlatformEnvironment} from '@px/shared/env';
import {plainToInstance} from 'class-transformer';
import omit from 'lodash/omit';
import {WINDOW_TOKEN} from '@px/cdk/window';
import {
  ISession,
  ISessionProviderService,
  Session,
  SessionProviderFacade,
  SessionSource,
} from '@px/shared/session-provider';

// todo: use sessionprovider instead of photographerauthfacade
// setup everywhere app initialization for sessionprovider
@Injectable()
export class AuthGuard {
  protected readonly router = inject(Router);
  protected readonly window: Window = inject(WINDOW_TOKEN);
  protected readonly platformEnvironment = inject(PlatformEnvironment);
  protected readonly sessionProviderFacade = inject(SessionProviderFacade);

  protected removeSessionParams(url: string, queryParams: Params): void {
    const [path] = url.split('?');

    this.router.navigate([path], {
      queryParams: omit(queryParams, 'session', 'redirectedFromOAuth'),
    });
  }

  protected setQueryParamsSession(
    token: string,
    access: string | undefined,
    refreshId: string,
    expiration: number
  ): void {
    const sessionService = this.sessionProviderFacade.getSessionService() as ISessionProviderService;

    sessionService.updateSession(
      plainToInstance(Session, {
        token,
        access,
        expiration,
        refreshId,
      } as ISession),
      SessionSource.QUERY_PARAMS
    );
  }

  validate(queryParams: Params, url: string, redirectTo?: string): Observable<boolean> {
    const sessionService = this.sessionProviderFacade.getSessionService() as ISessionProviderService;
    const {session} = queryParams;
    let parsedSession: ISession | null;

    try {
      parsedSession = JSON.parse(this.window.atob(session));
    } catch (e) {
      parsedSession = null;
    }

    if (parsedSession) {
      this.setQueryParamsSession(
        parsedSession.token,
        parsedSession.access,
        parsedSession.refreshId,
        parsedSession.expiration
      );

      return of(true).pipe(tap(() => this.removeSessionParams(url, queryParams)));
    }

    if (!sessionService.hasSession()) {
      if (!redirectTo) {
        return sessionService.handleNoSession(url);
      }

      if (redirectTo) {
        this.window.location.href = redirectTo;
      }

      return of(false);
    }

    return of(true);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.validate(route.queryParams, state.url, route.data.redirectTo);
  }
}
