import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {SkeletonBorderRadiusEnum} from '../../models/skeleton-border-radius.enum';

@Component({
  selector: 'mds-skeleton-bone',
  templateUrl: './skeleton-bone.component.html',
  styleUrls: ['./skeleton-bone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonBoneComponent {
  @HostBinding('style.width')
  private widthInternal: string | null = null;

  @HostBinding('style.height')
  private heightInternal: string | null = null;

  @Input() borderRadius: keyof typeof SkeletonBorderRadiusEnum = 'default';

  @Input() set width(value: string | number) {
    this.widthInternal = typeof value === 'number' ? `${value}px` : value;
  }

  @Input() set height(value: string | number) {
    this.heightInternal = typeof value === 'number' ? `${value}px` : value;
  }
}
