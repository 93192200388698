import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AuthService as AuthBase,
  IApiResponse as ApiResponse,
  IPreferencesService,
  PREFERENCES_SERVICE,
} from '@px/shared/api';
import {IPssAuthService, ISaveLastTransitionGqlInput, ISaveLastTransitionPicInput} from '../types/auth.models';
import {Preferences} from '../../models/preferences.model';

@Injectable({
  providedIn: 'root',
})
export class AuthAdapterService extends AuthBase implements IPssAuthService {
  private readonly preferencesService = inject<IPreferencesService<Preferences>>(PREFERENCES_SERVICE);

  saveLastTransition(data: ISaveLastTransitionPicInput): Observable<ApiResponse> {
    const inputMetaData: ISaveLastTransitionGqlInput = {
      lastChosenTransitionType: data.transition_id,
      lastChosenTransitionDuration: data.transition_duration,
    };

    return this.preferencesService.save({meta_data: inputMetaData});
  }
}
