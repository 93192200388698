import {Action, createAction} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {IPaginator, ManagerSlideshow, SearchParams, SurveysData} from 'apps/pss/src/app/models/manager.model';
import {NewSlideshowDialogData} from 'apps/pss/src/app/models/new-slideshow-dialog.model';
import {ConfirmDialogData} from 'apps/pss/src/app/models/confirm-dialog.model';
import {DownloadVideoType} from '@px/pss/feature-tracking';
import {SortTypeEnum} from '@px/legacy/feature-page-manager';
import {SlideshowsInsertStrategy} from './sliedeshows-insert-stratege.enum';

export enum SlideshowsActionTypes {
  LOAD_PAGE = '[Slideshows] Load Page',
  LOAD_FAILURE = '[Slideshows] Load Failure',
  SEARCH = '[Slideshows] Search',
  SORT = '[Slideshows] Sort',
  DELETE = '[Slideshows] Delete',
  DELETE_SUCCESS = '[Slideshows] Delete Success',
  DELETE_FAILURE = '[Slideshows] Delete Failure',
  DELETE_SLIDESHOW_CONFIRM_DIALOG_OPEN = '[Slideshows] Delete slideshow confirm dialog open',
  GET_DATA_FOR_DUPLICATED_SLIDESHOW = '[Manager] Get data for duplicated slideshow',
  GET_DATA_FOR_NEW_SLIDESHOW = '[Manager] Get data for new slideshow',
  NEW_SLIDESHOW_DIALOG_OPEN = '[Manager] New slideshow dialog open',
  VIDEO_READY = '[Manager] Video Ready',
  ADD_SLIDESHOW_ERROR_PROCESSING = '[Manager] Add Slideshow Error Processing',

  SURVEY_DIALOG_OPEN = '[Survey] Dialog open',
  SURVEY_BEGIN = '[Survey] Begin',
  SURVEY_SELECT_ANSWER = '[Survey] SELECT ANSWER',
  SURVEY_NEXT = '[Survey] Next',
  SURVEY_SELECT = '[Survey] Select',
  SURVEY_SAVE = '[Survey] Save',
  SURVEY_SAVE_SUCCESS = '[Survey] Save Success',
  SURVEY_SAVE_FAILURE = '[Survey] Save Failure',
  SURVEY_DESTROY_PROGRESS = '[Survey] Destroy Progress',

  DESTROY = '[Manager] Destroy',
}

export const addOrUpdateSlideshow = createAction(
  '[Slideshows] Add or Update Slideshow',
  (payload: ManagerSlideshow, unique_identifier?: string) => {
    return {
      payload,
      unique_identifier,
    };
  }
);

export class LoadPage implements Action {
  readonly type = SlideshowsActionTypes.LOAD_PAGE;
  constructor(public page: number) {}
}

export const loadSuccess = createAction(
  '[Slideshows] Load Success',
  (
    slideshow: ManagerSlideshow<Date>[],
    current_slideshows_count: number,
    slide_shows_limit: number,
    surveysObj: SurveysData,
    paginator: IPaginator,
    insertStrategy: SlideshowsInsertStrategy
  ) => ({
    slideshow,
    slide_shows_limit,
    current_slideshows_count,
    surveysObj,
    paginator,
    insertStrategy,
  })
);

export class LoadFailure implements Action {
  readonly type = SlideshowsActionTypes.LOAD_FAILURE;

  constructor(public payload) {}
}

export class Search implements Action {
  readonly type = SlideshowsActionTypes.SEARCH;

  constructor(public payload: SearchParams) {}
}

export class Sort implements Action {
  readonly type = SlideshowsActionTypes.SORT;

  constructor(public slideshowsSortType: SortTypeEnum) {}
}

export class Delete implements Action {
  readonly type = SlideshowsActionTypes.DELETE;

  constructor(public payload: {slideshow: ManagerSlideshow}) {}
}

export class DeleteSuccess implements Action {
  readonly type = SlideshowsActionTypes.DELETE_SUCCESS;

  constructor(public payload: ManagerSlideshow<Date | number>) {}
}

export class DeleteFailure implements Action {
  readonly type = SlideshowsActionTypes.DELETE_FAILURE;

  constructor(public payload: Record<string, string>) {}
}

export class DeleteSlideshowConfirmDialogOpen implements Action {
  readonly type = SlideshowsActionTypes.DELETE_SLIDESHOW_CONFIRM_DIALOG_OPEN;

  constructor(public payload: ConfirmDialogData) {}
}

export class GetDataForDuplicatedSlideshow implements Action {
  readonly type = SlideshowsActionTypes.GET_DATA_FOR_DUPLICATED_SLIDESHOW;

  constructor(public payload: string) {}
}

export class GetDataForNewSlideshow implements Action {
  readonly type = SlideshowsActionTypes.GET_DATA_FOR_NEW_SLIDESHOW;
}

export class NewSlideshowDialogOpen implements Action {
  readonly type = SlideshowsActionTypes.NEW_SLIDESHOW_DIALOG_OPEN;

  constructor(public payload: Partial<NewSlideshowDialogData>) {}
}

export class VideoReady implements Action {
  readonly type = SlideshowsActionTypes.VIDEO_READY;

  constructor(
    readonly uniqueIdentifier: string,
    readonly videoUrl: string,
    readonly videoType: DownloadVideoType
  ) {}
}

export class AddSlideshowErrorProcessing implements Action {
  readonly type = SlideshowsActionTypes.ADD_SLIDESHOW_ERROR_PROCESSING;

  constructor(
    public response: HttpErrorResponse,
    public duplicateError?: boolean
  ) {}
}

export class SurveyDialogOpen implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_DIALOG_OPEN;
}

export class SurveyBegin implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_BEGIN;
}

export class SurveySelectAnswer implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_SELECT_ANSWER;

  constructor(public payload: {questionId: number; optionId: number}) {}
}

export class SurveyNext implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_NEXT;
}

export class SurveySelect implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_SELECT;

  constructor(public payload: number) {}
}

export class SurveySave implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_SAVE;
}

export class SurveySaveSuccess implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_SAVE_SUCCESS;
}

export class SurveySaveFailure implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_SAVE_FAILURE;

  constructor(public payload: Record<string, string>) {}
}

export class SurveyDestroyProgress implements Action {
  readonly type = SlideshowsActionTypes.SURVEY_DESTROY_PROGRESS;
}

export class Destroy implements Action {
  readonly type = SlideshowsActionTypes.DESTROY;
}

export type SlideshowsActionsUnion =
  | LoadPage
  | LoadFailure
  | Search
  | Sort
  | Delete
  | DeleteSuccess
  | DeleteFailure
  | DeleteSlideshowConfirmDialogOpen
  | GetDataForDuplicatedSlideshow
  | GetDataForNewSlideshow
  | NewSlideshowDialogOpen
  | VideoReady
  | AddSlideshowErrorProcessing
  | SurveyDialogOpen
  | SurveyBegin
  | SurveyNext
  | SurveySelectAnswer
  | SurveySelect
  | SurveySave
  | SurveySaveSuccess
  | SurveySaveFailure
  | SurveyDestroyProgress
  | Destroy
  | ReturnType<typeof addOrUpdateSlideshow>
  | ReturnType<typeof loadSuccess>;
