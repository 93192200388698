import {ChangeDetectorRef, Optional, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {map, switchMap} from 'rxjs/operators';
import {Languages, PlatformEnvironment} from '@px/shared/env';
import {AudioCategoryId} from '@px/audio-domain';

@UntilDestroy()
@Pipe({
  name: 'audioCategory',
})
export class AudioCategoryPipe implements PipeTransform {
  private static readonly DEFAULT_KEY = 'UNKNOWN';
  private static readonly MY_UPLOADS_KEY = 'My Uploads';
  private static readonly BEATS_KEY = 'Beats';
  private static readonly CALM_MELODIES_KEY = 'Calm Melodies';
  private static readonly HAPPY_KEY = 'Happy';
  private static readonly EMOTIONAL_KEY = 'Emotional';
  private static readonly EPIC_BEAT_MATCHING_KEY = 'Epic Beat-Matching';
  private static readonly PARTY_TIME_KEY = 'Party Time';
  private static readonly LOVE_SONGS_KEY = 'Love Songs';

  private input$ = new BehaviorSubject<AudioCategoryId | null>(null);
  private value = this.translate.instant(AudioCategoryPipe.DEFAULT_KEY);

  constructor(
    private readonly translate: TranslateService,
    private readonly platform: PlatformEnvironment,
    @Optional() private readonly cdr?: ChangeDetectorRef
  ) {
    this.input$
      .pipe(
        switchMap(value => this.getAsyncTranslate(value)),
        untilDestroyed(this)
      )
      .subscribe((translate: string) => {
        this.value = translate;
        this.cdr?.markForCheck();
      });
  }

  private getTranslate(
    method: 'async' | 'sync',
    value: AudioCategoryId | null,
    lang?: Languages
  ): Observable<string> | string {
    let fn: (key: string) => Observable<string> | string;

    if (lang) {
      if (method === 'async') {
        fn = (key: string): Observable<string> => this.translate.getTranslation(lang).pipe(map(values => values[key]));
      } else {
        fn = (key: string): string => this.translate.translations[lang][key]();
      }
    } else {
      if (method === 'async') {
        fn = this.translate.get.bind(this.translate);
      } else {
        fn = this.translate.instant.bind(this.translate);
      }
    }

    switch (value) {
      case AudioCategoryId.MY_UPLOADS:
        return fn(AudioCategoryPipe.MY_UPLOADS_KEY);

      case AudioCategoryId.BEATS:
        return fn(AudioCategoryPipe.BEATS_KEY);

      case AudioCategoryId.CALM_MELODIES:
        return fn(AudioCategoryPipe.CALM_MELODIES_KEY);

      case AudioCategoryId.HAPPY:
        return fn(AudioCategoryPipe.HAPPY_KEY);

      case AudioCategoryId.EMOTIONAL:
        return fn(AudioCategoryPipe.EMOTIONAL_KEY);

      case AudioCategoryId.EPIC_BEAT_MATCHING:
        return fn(AudioCategoryPipe.EPIC_BEAT_MATCHING_KEY);

      case AudioCategoryId.PARTY_TIME:
        return fn(AudioCategoryPipe.PARTY_TIME_KEY);

      case AudioCategoryId.LOVE_SONGS:
        return fn(AudioCategoryPipe.LOVE_SONGS_KEY);

      default:
        return fn(AudioCategoryPipe.DEFAULT_KEY);
    }
  }

  private getSyncTranslate(value: AudioCategoryId | null, lang?: Languages): string {
    return this.getTranslate('sync', value, lang) as string;
  }

  private getAsyncTranslate(value: AudioCategoryId | null, lang?: Languages): Observable<string> {
    return this.getTranslate('async', value, lang) as Observable<string>;
  }

  getBaseTranslation(value: AudioCategoryId | null): string {
    return this.getSyncTranslate(value, this.platform.BASE_LANG);
  }

  transform(input: string | number): string {
    const inputParsed = Number(input) as AudioCategoryId;

    const {value} = this.input$;

    if (!value || value !== input) {
      this.input$.next(Number(input));
    }

    return this.value ?? this.getSyncTranslate(inputParsed);
  }
}
